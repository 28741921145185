import './style.scss';
import { parallaxInit } from './utils/functions';

document.addEventListener('DOMContentLoaded', () => {
	const parallaxEls = document.querySelectorAll('.wp-block-psb-parallax');
	parallaxEls.forEach(parallaxEl => {
		const parallaxImgEl = parallaxEl.querySelector('.psbParallaxImg');

		if (parallaxImgEl) {
			parallaxInit(parallaxImgEl, parallaxImgEl.parentElement);

			document.addEventListener('scroll', () => {
				parallaxInit(parallaxImgEl, parallaxImgEl.parentElement);
			});
		}
	});
});